import {
  container,
  mlAuto,
  mrAuto,
  title,
  main,
  mainRaised
} from "assets/jss/material-kit-pro-react.jsx";

import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.jsx";
import tooltipsStyle from "assets/jss/material-kit-pro-react/tooltipsStyle.jsx";

const partnersCorner = {
  container,
  mlAuto,
  mrAuto,
  title,
  main,
  mainRaised,
  ...teamsStyle,
  ...tooltipsStyle,
  color: {
    color: `blue`
  },
  size:{
    fontSize:`0.9rem`
  }
};
export default partnersCorner;
