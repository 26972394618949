import React from 'react';
import { graphql } from 'gatsby';
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";
import PartnersLogin from "./partnersCornerPartnersLogin.jsx";
import AgentsCorner from "./partnersCornerAgentsCorner.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import customStaticPageStyle from "ps-assets/jss/customStaticPageStyle.jsx";
import Layout from "ps-components/Layout/Layout.jsx";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

let url = process.env.BASE_URL;
const PartnersCorner = ({ classes, data }) => {
  var Images = data.allStrapiImages.edges;
  var bwImage = Images[0];
  var clrImage = Images[1];
  var navPillArr = data.allStrapiSections.edges.map(document => {
    var obj = {};
    let content;
    switch (document.node.strapiId) {
      case 226:
        content = <PartnersLogin />;
        break;
      case 227:
        content = <AgentsCorner />;
        break;
    }

    obj.tabButton = document.node.title;
    obj.tabImage = document.node.icon && document.node.icon.publicURL;
    obj.tabContent = content;
    return obj;
  });

  return (
    <Layout
      image1={
        bwImage &&
        bwImage.node &&
        bwImage.node.content &&
        bwImage.node.content.childImageSharp.fluid
      }
      image2={
        clrImage &&
        clrImage.node &&
        clrImage.node.content &&
        clrImage.node.content.childImageSharp.fluid
      }
      small={true}
      title={``}
      summary={``}
    >
      <Helmet key="helmetTags">
        <title>Partners Corner | StarHealth.in</title>
      </Helmet>
      {process.env.ENABLE_HELMET == `true` && (
        <Helmet key="helmetTags">
          <link
            rel="canonical"
            href={url + `partners-corner`}
          />
          <meta name="title" content="Partners Corner | StarHealth.in" />
          <meta name="twitter:title" content="Partners Corner | StarHealth.in" />
          <meta
            name="description"
            content="Star Health Insurance Partners Corner"
          />
          <meta
            name="keywords"
            content="Buy Online Health Insurance Plans, Health Insurance, Accident Care, Overseas Travel, Insurance Policy, Insurance Agent Corner, Star Health and Allied Insurance Company Limited, Chennai, India, Portability, Star Health Products, Renew  Policy Online, Health Insurance Specialist In India."
          />
          <meta property="og:title" content="Partners Corner | StarHealth.in" />
          <meta property="og:description" content="Star Health Insurance Partners Corner" />
          <meta property="og:url" content={url + `partners-corner`}/>
          <meta name="Partners Corner | StarHealth.in" />
          <meta name="twitter:description" content="Star Health Insurance Partners Corner" />
          <meta property="twitter:url" content={url + `partners-corner`} />
        </Helmet>
      )}
      <div className={classNames(classes.main, classes.mainRaised)} style={{ marginBottom: 100 }}>
        <div className={classes.section}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem style={{ paddingTop: `70px` }}>
                <NavPills
                  color="primary"
                  tabs={navPillArr}
                  alignCenter
                  variant={true}
                />
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </Layout>
  );
};
PartnersCorner.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
};
export default withStyles(customStaticPageStyle)(PartnersCorner);


export const partnersCornerQuery = graphql`
 query partnersCorner {
    allStrapiSections(
      filter:{
        category: {eq: "partners_corner"}, title: {ne : "Partners Corner" }
      },
      sort:{
        fields: sequence,
        order:ASC
      }
      ){
      edges{
        node{
          title,
          strapiId
          icon{
            publicURL
          }
        }
      }
    }
    allStrapiImages(
      filter: { section: { category: { eq: "partners_corner" } } }
    ) {
      edges {
        node {
          id
          sequence
          content {
            publicURL
            childImageSharp{
              fluid(quality:100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }`;

