import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import Img from "gatsby-image";
import withStyles from "@material-ui/core/styles/withStyles";
import partnersCornerStyle from "ps-assets/jss/partnersCornerStyle";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import PropTypes from "prop-types";
import moment from "moment";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const partnersCornerPartnerLogins = ({ classes }) => {
  const [open, setOpen] = React.useState({
    Network_Hospital_Empanelment: false,
    Diagnostic_Centre_Empanelment: false,
    Home_Health_Care_Centre_Empanelment: false
  });

  const toolTipTitles=[
    `Network Hospital Empanelment`, 
    `Diagnostic Centre Empanelment`,
    `Home Health Care Centre Empanelment`
  ]
  const toolTipTitlesAlias=[
    `Network_Hospital_Empanelment`, 
    `Diagnostic_Centre_Empanelment`,
    `Home_Health_Care_Centre_Empanelment`
  ]
  const toolTipString = [ 
    `Online Empanelment facility will be available from 1st to 20th of every month.`, 
    `Please contact network.hosp@starhealth.in / 044-46506582`,
    `Please contact empanelment.network@starhealth.in / 044-46506582`
  ]
  
  function getToolTipAlias(title) {
    const index = toolTipTitles.indexOf(title)
    return toolTipTitlesAlias[index]
  }

  function handleTooltipClose(title) {
    const tooltipalias = getToolTipAlias(title)
    setOpen(prev => ({...prev, [tooltipalias]: false}));
  }

  function handleTooltipOpen(title) {
    const tooltipalias = getToolTipAlias(title)
    setOpen({...open, [tooltipalias]: true});
  }


  return (
    <StaticQuery
      query={graphql`
    query PartnersLoginPartnersCornerQuery {
        allStrapiEntities(
          filter:{
           category : {eq: "partner_logins"},
          }
          ){
          edges {
            node {
              id,
              title,
              category,
              summary,
              url,
              description,
              Image{
                childImageSharp{
                  fixed(width:50,height:50){
                        src,
                        srcSet,
                        base64,
                        height,
                        width
                    }
                  }
                }
              }
            }
        
        }
    }
    `}
      render={data => {
        let today = new Date(moment().format(`YYYY-MM-DD`)).getDate();
        // let end_date = new Date(`2020-07-15`);
        // let intermediary_end_date = new Date(`2020-05-15`);
        // let intermediary_start_date = new Date(`2020-05-03`);
        let enableNetworkEnpanelment = false;
        // let notAvailableString = `Online Empanelment facility will be available from 1st to 20th of every month.`;
        // let notAvailableString = 'Our Online Hospital Empanelment process is undergoing some technological Advancements. In view of which, we shall reopen the registration for new empanelment of hospitals from 1st November 2021.';
        
        const getToolTipTitle = (title) =>{
          const index = toolTipTitles.indexOf(title)
          return toolTipString[index]
        }
        if(today >= 1 && today <= 20){
        // if(today < 0){
          enableNetworkEnpanelment = true;
        }

        // if(intermediary_start_date <= today && today < intermediary_end_date){
        //   enableNetworkEnpanelment = false;
        // }
        // else {
        //   if (today < end_date) {
        //     enableNetworkEnpanelment = true;
        //   }
        // }

        return (
          <div className={classes.team}>
            <div className={classes.container}>
              <GridContainer>
                {data.allStrapiEntities.edges.map((document, k) => {
                  if (!toolTipTitles.includes(document.node.title) || (document.node.title === `Network Hospital Empanelment` && enableNetworkEnpanelment)) {
                    return (
                      <GridItem key={k} xs={12} sm={12} md={4}>
                        <a href={document.node.url} target="_blank" rel="noopener noreferrer">
                          <VerticalCard
                            noShadow
                            onHoverEffect
                            title={document.node.title}
                            description={
                              <Img
                                fixed={
                                  document.node.Image.childImageSharp
                                    .fixed
                                }
                              />
                            }
                            alignItems="center"
                            style={{ marginTop: `15px`, marginBottom: `15px` }}
                          />
                        </a>
                      </GridItem>
                    );
                  } else {
                    return (
                      <GridItem key={k} xs={12} sm={12} md={4}>
                        <ClickAwayListener onClickAway={() => handleTooltipClose(document.node.title)}>
                          <div>
                            <Tooltip
                              placement="top"
                              PopperProps={{
                                disablePortal: true,
                              }}
                              onClose={() => handleTooltipClose(document.node.title)}
                              open={open[getToolTipAlias(document.node.title)]}
                              disableFocusListener
                              disableHoverListener
                              disableTouchListener
                              title={getToolTipTitle(document.node.title)}
                              classes={{ popper: classes.popper,tooltip:classes.size }}
                            >
                              <div style={{ padding: 0, margin: 0, width: `100%`, cursor: `pointer` }} onClick={() => handleTooltipOpen(document.node.title)}>
                                <VerticalCard
                                  noShadow
                                  onHoverEffect
                                  title={document.node.title}
                                  description={
                                    <Img
                                      fixed={
                                        document.node.Image.childImageSharp
                                          .fixed
                                      }
                                    />
                                  }
                                  alignItems="center"
                                  style={{ marginTop: `15px`, marginBottom: `15px` }}
                                />
                              </div>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      </GridItem>
                    );
                  }
                })}
              </GridContainer>
            </div>
          </div>);
      }}
    />
  );
};
partnersCornerPartnerLogins.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(partnersCornerStyle)(partnersCornerPartnerLogins);

