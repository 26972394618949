import React from "react";
import { StaticQuery, graphql } from "gatsby";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem";
import VerticalCard from "ps-components/Card/VerticalCard.jsx";
import classNames from "classnames";
import withStyles from "@material-ui/core/styles/withStyles";
import partnersCornerStyle from "ps-assets/jss/partnersCornerStyle";
import PropTypes from "prop-types";
const partnersCornerAgentsCorner = ({ classes }) => (
  <StaticQuery
    query={graphql`
    query AgentsCornerPartnersCornerQuery {
        allStrapiEntities(
          filter:{
           category : {eq: "agents_corner"},
          }
          ){
          edges {
            node {
              id,
              title,
              category,
              summary,
              description,
              url,
              displayType
              }
            }
        
        }
    }
    `}
    render={data => (
      <div className={classes.team}>
        <div className={classes.container}>
          <GridContainer item md={10} className={classNames(classes.mlAuto, classes.mrAuto)}>
            <GridItem xs={12} sm={12} md={12}>
              <div style={{ marginBottom: 40 }}>
                {
                  data.allStrapiEntities.edges[3].node.description.split(`\n`).filter(temp => temp !== ``).map((para, key) => {
                    return (
                      <h5 key={key} className={`${classes.description}`} style={{ textAlign: `center`, marginBottom: 20 }}>
                        {para}
                      </h5>
                    );
                  })
                }
              </div>
            </GridItem>

            {data.allStrapiEntities.edges.filter(v => v.node.displayType === `link`).map((documents, key) => {
              return (
                <GridItem xs={12} sm={6} md={4} key={key}>
                  <VerticalCard
                    noShadow
                    onHoverEffect
                    description={
                      <div>
                        <p className={classNames(classes.description, classes.textCenter)}>
                          {documents.node.title}
                          {
                            documents.node.url.split(`,`).map((agentsData, index) => {
                              return (
                                <a rel="noopener noreferrer" key={index} className={classNames(classes.description, classes.textCenter, classes.color)} href={agentsData}>
                                  {` ` + documents.node.description.split(`/`)[index] + ` `}
                                  {
                                    documents.node.description.split(`/`).length - 1 !== index &&
                                    `/`
                                  }
                                </a>
                              );
                            })
                          }
                          {documents.node.summary}
                        </p>
                      </div>
                    }
                    alignItems="center"
                    style={{ marginTop: `15px`, marginBottom: `15px` }}
                  />
                </GridItem>
              );
            })}
          </GridContainer>
        </div>
      </div>
    )}
  />
);
partnersCornerAgentsCorner.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(partnersCornerStyle)(partnersCornerAgentsCorner);

